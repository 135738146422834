import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import login from './login/reducers'
import workspaces from './workspaces/reducers'
import zones from './zones/reducers'
import providers from './providers/reducers'
import serviceOfferings from './serviceOfferings/reducers'
import instances from './instances/reducers'
import volumes from './volumes/reducers'
import snapshots from './snapshots/reducers'
import securityGroups from './securityGroups/reducers'
import elasticIps from './elasticIps/reducers'
import keyPairs from './keyPairs/reducers'
import subnets from './subnets/reducers'
import vpcs from './vpcs/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    login,
    settings,
    workspaces,
    zones,
    providers,
    serviceOfferings,
    instances,
    volumes,
    snapshots,
    securityGroups,
    elasticIps,
    keyPairs,
    vpcs,
    subnets,
  })
