export const actions = {
  FETCH_VOLUMES_REQUESTED: 'FETCH_VOLUMES_REQUESTED',
  FETCH_VOLUMES_SUCCESS: 'FETCH_VOLUMES_SUCCESS',
  FETCH_VOLUMES_FAILURE: 'FETCH_VOLUMES_FAILURE',
}

export const fetchVolumesRequested = () => ({
  type: actions.FETCH_VOLUMES_REQUESTED,
})

export const fetchVolumesSuccess = payload => ({
  type: actions.FETCH_VOLUMES_SUCCESS,
  payload,
})

export const fetchVolumesFailure = error => ({
  type: actions.FETCH_VOLUMES_FAILURE,
  error,
})
