import React from 'react'
import {Col, Row} from 'antd'
import constants from 'web-constant';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {DollarCircleFilled} from "@ant-design/icons";
import ProfileMenu from './ProfileMenu'
import ProjectManagement from './ProjectManagement'
import MenuServices from './MenuServices'
import styles from './style.module.scss'
import formatCurrency from "../../../utils/FormatCurrency";

const mapStateToProps = ({ settings, user }) => ({
  showZone: settings.showZone,
  currentUser: user,
  showServiceMenu: settings.showServiceMenu,
})

@withRouter
@connect(mapStateToProps)
class TopBar extends React.Component {
  render() {
    const { showZone = 1, showServiceMenu = 1, currentUser } = this.props
    const remain = currentUser ? currentUser.remainCredits : 0;
    let creditColor = '#49aac6';
    if (remain < 30000) creditColor = '#fb434a';
    else if (remain < 100000) creditColor = '#D58321';
    return (
      <Row className={styles.topbar}>
        <Col span={12}>
          <Row>
            <Col className={styles.logo}>
              <a href={constants.ACCOUNT_CLIENT_URL}>
                <img src="/resources/images/epc-logo.png" alt="EPC-logo" />
              </a>
            </Col>
            {showServiceMenu &&
            <Col className="mr-4">
               <MenuServices />
            </Col>}
          </Row>
        </Col>
        <Col span={12}>
          <Row justify='end'>
            <Col style={{ textAlign: 'right' }}>
              <div className='mr-4'>
                <DollarCircleFilled style={{ color: '#D58321', marginRight: '10px' }} />
                <span style={{color: creditColor }}>{currentUser && formatCurrency(currentUser.remainCredits)}</span>
              </div>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <ProfileMenu />
            </Col>
            <Col style={{ textAlign: 'right' }}>
              {showZone && <ProjectManagement />}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default TopBar
