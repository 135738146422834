import React from 'react'
import {connect} from 'react-redux'
import {Button, Col, Menu, Modal, Row} from 'antd'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash'
import constant from 'web-constant';
import IconCustom from '../../../CleanUIComponents/IconCustom'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({
  currentUser: user,
})

@connect(mapStateToProps)
class MenuServices extends React.Component {
  state = {
    openMenuModal: false,
    selectedRow: null,
    allServices: {},
    favorites: []
  }

  componentDidMount() {
    this.loadAllServices()
    this.setState({
      favorites: [
        {name: 'Compute Engine', url: constant.CE_CLIENT_URL},
        {name: 'Mobile Farm', url: constant.DF_CLIENT_URL},
        {name: 'Custom Domain', url: constant.CD_CLIENT_URL},
        // {name: 'Simple Storage Service (S3)', url: constant.S3_CLIENT_URL},
        // {name: 'Relational Database (RDS)', url: constant.RDS_CLIENT_URL},
        // {name: 'Elastic File System', url: constant.EFS_CLIENT_URL},
        // {name: 'Team Workspace', url: constant.WS_CLIENT_URL},
      ]
    })
  }

  selectRow = record => {
    this.setState({
      selectedRow: record,
    })
  }

  getAllServices = (services, icon) => {
    const sv = _.cloneDeep(services)
    const title = sv[0]
    sv.shift()
    return (
      <Row style={{ padding: '0 0 30px 0' }}>
        <Col span={3}>
          <IconCustom iconName={icon} style={{ fontSize: 18 }} />
        </Col>
        <Col span={21}>
          <div className={styles['text-all-services-title']}>{title}</div>
          {sv.map(service => {
            if (service.url)
              return (
                <a
                  key={`${encodeURI(title)}_${encodeURI(service.name)}`}
                  href={service.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => this.setState({ openMenuModal: false })}
                >
                  <div className={styles['text-all-services']}>{service.name}</div>
                </a>
              )
            return (
              <div
                key={`${encodeURI(title)}_${encodeURI(service.name)}`}
                className={styles['text-all-services-disabled']}
              >
                {service.name}
              </div>
            )
          })}
        </Col>
      </Row>
    )
  }

  loadAllServices = () => {
    const services = {
      compute: [
        'Compute',
        { name: 'Compute Engine', url: constant.CE_CLIENT_URL },
      ],
      storage: [
        'Storage',
        { name: 'Simple Storage Service (S3)', url: constant.S3_CLIENT_URL },
        { name: 'Elastic File System (EFS)', url: constant.EFS_CLIENT_URL },
      ],
      containers: [
        'Web & Mobile',
        { name: 'Custom Domain', url: constant.CD_CLIENT_URL },
        { name: 'Mobile Farm', url: constant.DF_CLIENT_URL },
      ],
      developer: [
        'App catalog',
        { name: 'Marketplace', url: constant.MARKET_CLIENT_URL },
      ],
      empty: [

      ],
      security: [
        'Database',
        { name: 'Relational Database (RDS)', url: constant.RDS_CLIENT_URL },
        { name: 'Non Relational Database (Non RDS)', url: constant.NON_RDS_CLIENT_URL },
        { name: 'Database In-Memory', url: constant.REDIS_CLIENT_URL },
      ],
      containersStorage: [
        'Containers',
        { name: 'Elastic Container Registry (ECR)', url: constant.ECR_CLIENT_URL },
      ],
    }
    this.setState({
      allServices: services,
    })
    return services
  }

  MenuModal = () => {
    const { openMenuModal, selectedRow, allServices, favorites } = this.state
    return (
      <Modal
        width="96%"
        height="auto"
        className={styles.modal}
        // title="All Services"
        centered
        visible={openMenuModal}
        onCancel={() => this.setState({ openMenuModal: false })}
        footer={null}
      >
        <Row>
          <Col span={6}>
            <Menu selectable={false} styles={{ position: 'absolute' }}>
              <Menu.ItemGroup
                title={
                  <div style={{ fontWeight: 700, fontSize: 18 }}>
                    <i className={`${styles.icon} fa fa-star`} />
                    <span className="d-xl-inline">
                      <FormattedMessage id="topBar.menuServices.Favorites" />
                    </span>
                  </div>
                }
              >
                {favorites.map(key => (
                  key.url &&
                  <Menu.Item key={`Favorite_CE${key.name}`} className='my-0'>
                    <a
                      key={`Favorite_CE${key.name}`}
                      target='_blank'
                      rel="noopener noreferrer"
                      href={key.url}
                      onClick={() => this.setState({openMenuModal: false})}
                    >
                      <i className={`${styles.icon} fa fa-star`} />&nbsp;{key.name}
                    </a>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            </Menu>
          </Col>
          <Col span={18}>
            <Row style={{ marginLeft: 15, marginBottom: 20 }}>
              <h3>
                <strong>All Services</strong>
              </h3>
            </Row>
            <Row style={{ marginLeft: 25 }}>
              <Col span={6}>
                {this.getAllServices(allServices.compute || [], 'CloudServerOutlined')}
                {this.getAllServices(allServices.containersStorage || [], 'ContainerOutlined')}
              </Col>
              <Col span={6}>
                {this.getAllServices(allServices.storage || [], 'DatabaseOutlined')}
              </Col>
              <Col span={6}>
                {this.getAllServices(allServices.security || [], 'DatabaseOutlined')}
              </Col>
              <Col span={6}>
                {this.getAllServices(allServices.containers || [], 'CodeSandboxOutlined')}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    )
  }

  render() {
    const { openMenuModal } = this.state

    return (
      <>
        <Button
          className={styles.button}
          onClick={() => {
            this.loadAllServices()
            this.setState({ openMenuModal: !openMenuModal })
          }}
        >
          <span className="d-none d-xl-inline">
            <span className={styles['header-text-service']}>
              <FormattedMessage id="topBar.services" />
            </span>
          </span>
        </Button>
        {this.MenuModal()}
      </>
    )
  }
}

export default MenuServices
