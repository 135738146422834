import React, { Fragment } from 'react'
import constant from 'web-constant'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from 'components/LayoutComponents/Loader'
import { getJson } from 'utils/utils'
import { PropTypes } from 'prop-types'
import LoginLayout from './Login'
import MainLayout from './Main'
import DashboardLayout from './Dashboard'
import { setUserData } from '../../redux/user/actions'
import PublicLayout from './Public'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
  dashboard: DashboardLayout,
}

const mapStateToProps = state => ({
  setUserDataRedux: PropTypes.func.isRequired,
  user: state.user,
})
const dispatchStateToProps = dispatch => ({
  setUserDataRedux: data => dispatch(setUserData(data)),
})

@withRouter
@connect(mapStateToProps, dispatchStateToProps)
class IndexLayout extends React.PureComponent {
  constructor(props) {
    super(props)
    this.checkJwtAuth = this.checkJwtAuth.bind(this)
    this.checkJwtAuth()
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  checkJwtAuth = () => {
    const { setUserDataRedux } = this.props
    getJson('/user/currentUser')
      .then(user => {
        if (user.forceLogin) {
          window.location.href = `${constant.AUTH_URL}/force-logout`
        } else {
          setUserDataRedux(user)
        }
      })
      .catch(err => {
        window.location.href = `${constant.AUTH_URL}/logout`
      })
  }

  previousPath = ''

  render() {
    const {
      children,
      location: { pathname, search },
      user,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/dashboard') {
        return 'dashboard'
      }
      if (
        [
          '/ce/launch-instance-wizard',
          '/ce/elastic-block-store/volumes/create-volume',
          '/ce/elastic-block-store/snapshots/create-snapshot',
          '/ce/elastic-block-store/vm-snapshots/create-vm-snapshot',
          '/ce/network/security-groups/create-security-group',
        ].includes(pathname)
      ) {
        return 'dashboard'
      }
      return 'main'
    }

    const Container = Layouts[getLayout()]

    const getTitle = () => process.env.REACT_APP_MENU_TITLE || ''

    const BootstrappedLayout = () => {
      if (!user.id) {
        return <Loader />
      }
      return <Container>{children}</Container>
    }

    return (
      <Fragment>
        <Helmet titleTemplate="EP | %s" title={getTitle()} />
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
