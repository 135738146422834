export const actions = {
  FETCH_SERVICE_OFFERINGS_REQUESTED: 'FETCH_SERVICE_OFFERINGS_REQUESTED',
  FETCH_SERVICE_OFFERINGS_SUCCESS: 'FETCH_SERVICE_OFFERINGS_SUCCESS',
  FETCH_SERVICE_OFFERINGS_FAILURE: 'FETCH_SERVICE_OFFERINGS_FAILURE',
}

export const fetchServiceOfferingsRequested = () => ({
  type: actions.FETCH_SERVICE_OFFERINGS_REQUESTED,
})

export const fetchServiceOfferingsSuccess = payload => ({
  type: actions.FETCH_SERVICE_OFFERINGS_SUCCESS,
  payload,
})

export const fetchServiceOfferingsFailure = error => ({
  type: actions.FETCH_SERVICE_OFFERINGS_FAILURE,
  error,
})
