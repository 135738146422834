export const actions = {
  FETCH_KEY_PAIRS_REQUESTED: 'FETCH_KEY_PAIRS_REQUESTED',
  FETCH_KEY_PAIRS_SUCCESS: 'FETCH_KEY_PAIRS_SUCCESS',
  FETCH_KEY_PAIRS_FAILURE: 'FETCH_KEY_PAIRS_FAILURE',
}

export const fetchKeyPairsRequested = () => ({
  type: actions.FETCH_KEY_PAIRS_REQUESTED,
})

export const fetchKeyPairsSuccess = payload => ({
  type: actions.FETCH_KEY_PAIRS_SUCCESS,
  payload,
})

export const fetchKeyPairsFailure = error => ({
  type: actions.FETCH_KEY_PAIRS_FAILURE,
  error,
})
