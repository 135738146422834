export const actions = {
  FETCH_PROVIDERS_REQUESTED: 'FETCH_PROVIDERS_REQUESTED',
  FETCH_PROVIDERS_SUCCESS: 'FETCH_PROVIDERS_SUCCESS',
  FETCH_PROVIDERS_FAILURE: 'FETCH_PROVIDERS_FAILURE',
}

export const fetchProvidersRequested = () => ({
  type: actions.FETCH_PROVIDERS_REQUESTED,
})

export const fetchProvidersSuccess = payload => ({
  type: actions.FETCH_PROVIDERS_SUCCESS,
  payload,
})

export const fetchProvidersFailure = error => ({
  type: actions.FETCH_PROVIDERS_FAILURE,
  error,
})
