export const actions = {
  FETCH_SNAPSHOTS_REQUESTED: 'FETCH_SNAPSHOTS_REQUESTED',
  FETCH_SNAPSHOTS_SUCCESS: 'FETCH_SNAPSHOTS_SUCCESS',
  FETCH_SNAPSHOTS_FAILURE: 'FETCH_SNAPSHOTS_FAILURE',
}

export const fetchSnapshotsRequested = () => ({
  type: actions.FETCH_SNAPSHOTS_REQUESTED,
})

export const fetchSnapshotsSuccess = payload => ({
  type: actions.FETCH_SNAPSHOTS_SUCCESS,
  payload,
})

export const fetchSnapshotsFailure = error => ({
  type: actions.FETCH_SNAPSHOTS_FAILURE,
  error,
})
