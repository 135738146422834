import { all, call, put, takeEvery } from 'redux-saga/effects'
import getVpcs from 'services/vpcs'
import { fetchVpcsFailure, fetchVpcsRequested, fetchVpcsSuccess } from './actions'

export function* fetchVpcs(action) {
  yield put(fetchVpcsRequested)
  const response = yield call(getVpcs, action.payload)
  if (response.error) {
    yield put(fetchVpcsFailure(response.error))
  } else {
    yield put(fetchVpcsSuccess(response.data))
  }
}

export function* watchFetchVpcs() {
  yield takeEvery('FETCH_VPCS', fetchVpcs)
}

export default function* rootSaga() {
  yield all([watchFetchVpcs()])
}
