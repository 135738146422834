import { all, call, put, takeEvery } from 'redux-saga/effects'
import getServiceOfferings from 'services/serviceOfferings'
import {
  fetchServiceOfferingsFailure,
  fetchServiceOfferingsRequested,
  fetchServiceOfferingsSuccess,
} from './actions'

export function* fetchServiceOfferings(action) {
  yield put(fetchServiceOfferingsRequested())
  const response = yield call(getServiceOfferings, action.payload)
  if (response.error) {
    yield put(fetchServiceOfferingsFailure(response.error))
  } else {
    yield put(fetchServiceOfferingsSuccess(response.data))
  }
}

export function* watchFetchServiceOfferings() {
  yield takeEvery('FETCH_SERVICE_OFFERINGS', fetchServiceOfferings)
}

export default function* rootSaga() {
  yield all([watchFetchServiceOfferings()])
}
