import { all, call, put, takeEvery } from 'redux-saga/effects'
import getSecurityGroups from 'services/securityGroups'
import {
  fetchSecurityGroupsFailure,
  fetchSecurityGroupsRequested,
  fetchSecurityGroupsSuccess,
} from './actions'

export function* fetchSecurityGroups() {
  yield put(fetchSecurityGroupsRequested())
  const response = yield call(getSecurityGroups)
  if (response.error) {
    yield put(fetchSecurityGroupsFailure(response.error))
  } else {
    yield put(fetchSecurityGroupsSuccess(response.data))
  }
}

export function* watchFetchSecurityGroups() {
  yield takeEvery('FETCH_SECURITY_GROUPS', fetchSecurityGroups)
}

export default function* rootSaga() {
  yield all([watchFetchSecurityGroups()])
}
