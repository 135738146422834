import { all, call, put, takeEvery } from 'redux-saga/effects'
import getKeyPairs from 'services/keyPairs'
import { fetchKeyPairsFailure, fetchKeyPairsRequested, fetchKeyPairsSuccess } from './actions'

export function* fetchKeyPairs() {
  yield put(fetchKeyPairsRequested())
  const response = yield call(getKeyPairs)
  if (response.error) {
    yield put(fetchKeyPairsFailure(response.error))
  } else {
    yield put(fetchKeyPairsSuccess(response.data))
  }
}

export function* watchFetchKeyPairs() {
  yield takeEvery('FETCH_KEY_PAIRS', fetchKeyPairs)
}

export default function* rootSaga() {
  yield all([watchFetchKeyPairs()])
}
