import React from 'react'
import { Menu, Dropdown, Spin } from 'antd'
import { connect } from 'react-redux'
import styles from './style.module.scss'

const mapStateToProps = ({ zones }) => ({
  listZones: zones.listZones,
  currentZone: zones.currentZone,
  loading: zones.loading,
})

@connect(mapStateToProps)
class ProjectManagement extends React.Component {
  state = {
    isLoading: false,
  }

  initZone = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'zones/LOAD_CURRENT_ZONE',
    })
  }

  componentDidMount() {
    this.initZone()
  }

  handleChangeZone = zoneId => {
    const { dispatch, currentZone } = this.props
    this.setState({ isLoading: true })
    if (zoneId !== currentZone.id) {
      dispatch({
        type: 'zones/CHANGE_ZONE',
        payload: { zoneId },
      })
      window.location.replace('/')
    }
    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 1000)
  }

  render() {
    const { listZones, currentZone, loading } = this.props
    const { isLoading } = this.state

    const menu = (
      <Menu selectable={false}>
        {listZones.map(zone => (
          <Menu.Item
            key={zone.id}
            style={{
              minWidth: 240,
              fontWeight: currentZone && zone.id === currentZone.id ? 700 : 400,
            }}
            disabled={!zone.allocationstate || zone.allocationstate === 'Disabled'}
            onClick={() => {
              this.handleChangeZone(zone.id)
            }}
          >
            {zone.name} &#40;{zone.allocationstate}&#41;
          </Menu.Item>
        ))}
      </Menu>
    )
    return (
      <Spin spinning={isLoading || loading}>
        <Dropdown overlay={menu} trigger={['click']}>
          <div className={styles.dropdown}>
            <span className="d-none d-xl-inline">
              <strong>{currentZone && currentZone.name ? currentZone.name : 'Zone'}</strong>
            </span>
          </div>
        </Dropdown>
      </Spin>
    )
  }
}

export default ProjectManagement
