import { all, call, put, takeEvery } from 'redux-saga/effects'
import getInstances from 'services/instances'
import { fetchInstancesFailure, fetchInstancesRequested, fetchInstancesSuccess } from './actions'

export function* fetchInstances(action) {
  yield put(fetchInstancesRequested())
  const response = yield call(getInstances, action.payload)
  if (response.error) {
    yield put(fetchInstancesFailure(response.error))
  } else {
    yield put(fetchInstancesSuccess(response.data))
  }
}

export function* watchFetchInstances() {
  yield takeEvery('FETCH_INSTANCES', fetchInstances)
}

export default function* rootSaga() {
  yield all([watchFetchInstances()])
}
