import { all, call, put, takeEvery } from 'redux-saga/effects'
import getSnapshots from 'services/snapshots'
import { fetchSnapshotsFailure, fetchSnapshotsRequested, fetchSnapshotsSuccess } from './actions'

export function* fetchSnapshots() {
  yield put(fetchSnapshotsRequested())
  const response = yield call(getSnapshots)
  if (response.error) {
    yield put(fetchSnapshotsFailure(response.error))
  } else {
    yield put(fetchSnapshotsSuccess(response.data))
  }
}

export function* watchFetchSnapshots() {
  yield takeEvery('FETCH_SNAPSHOTS', fetchSnapshots)
}

export default function* rootSaga() {
  yield all([watchFetchSnapshots()])
}
