import { getJson } from 'utils/utils'

const getServiceOfferings = async payload =>
  new Promise(resolve => {
    getJson('/service-offerings', payload || null)
      .then(res => resolve({ data: res.data[0] }))
      .catch(error => resolve({ error }))
  })

export default getServiceOfferings
