export const actions = {
  FETCH_ELASTIC_IPS_REQUESTED: 'FETCH_ELASTIC_IPS_REQUESTED',
  FETCH_ELASTIC_IPS_SUCCESS: 'FETCH_ELASTIC_IPS_SUCCESS',
  FETCH_ELASTIC_IPS_FAILURE: 'FETCH_ELASTIC_IPS_FAILURE',
}

export const fetchElasticIpsRequested = () => ({
  type: actions.FETCH_ELASTIC_IPS_REQUESTED,
})

export const fetchElasticIpsSuccess = payload => ({
  type: actions.FETCH_ELASTIC_IPS_SUCCESS,
  payload,
})

export const fetchElasticIpsFailure = error => ({
  type: actions.FETCH_ELASTIC_IPS_FAILURE,
  error,
})
