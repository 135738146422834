import { all, call, put, takeEvery } from 'redux-saga/effects'
import getProviders from 'services/providers'
import { fetchProvidersFailure, fetchProvidersRequested, fetchProvidersSuccess } from './actions'

export function* fetchProviders() {
  yield put(fetchProvidersRequested)
  const response = yield call(getProviders)
  if (response.error) {
    yield put(fetchProvidersFailure(response.error))
  } else {
    yield put(fetchProvidersSuccess(response.data))
  }
}

export function* watchFetchProviders() {
  yield takeEvery('FETCH_PROVIDERS', fetchProviders)
}

export default function* rootSaga() {
  yield all([watchFetchProviders()])
}
