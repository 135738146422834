export const actions = {
  FETCH_SUBNETS_REQUESTED: 'FETCH_SUBNETS_REQUESTED',
  FETCH_SUBNETS_SUCCESS: 'FETCH_SUBNETS_SUCCESS',
  FETCH_SUBNETS_FAILURE: 'FETCH_SUBNETS_FAILURE',
}

export const fetchSubnetsRequested = () => ({
  type: actions.FETCH_SUBNETS_REQUESTED,
})

export const fetchSubnetsSuccess = payload => ({
  type: actions.FETCH_SUBNETS_SUCCESS,
  payload,
})

export const fetchSubnetsFailure = error => ({
  type: actions.FETCH_SUBNETS_FAILURE,
  error,
})
