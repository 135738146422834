export const actions = {
  FETCH_VPCS_REQUESTED: 'FETCH_VPCS_REQUESTED',
  FETCH_VPCS_SUCCESS: 'FETCH_VPCS_SUCCESS',
  FETCH_VPCS_FAILURE: 'FETCH_VPCS_FAILURE',
}

export const fetchVpcsRequested = () => ({
  type: actions.FETCH_VPCS_REQUESTED,
})

export const fetchVpcsSuccess = payload => ({
  type: actions.FETCH_VPCS_SUCCESS,
  payload,
})

export const fetchVpcsFailure = error => ({
  type: actions.FETCH_VPCS_FAILURE,
  error,
})
