import { all, takeEvery, put, call } from 'redux-saga/effects'
import _ from 'lodash'
import {
  getWorkspace,
  currentWorkspace,
  createWorkspace,
  getListWorkspace,
} from 'services/workspaces'
import store from 'store'
import actions from './actions'

export function* CREATE_WORKSPACE({
  payload: { key, workspaceId, name, description, owner, members, switchWorkspace },
}) {
  yield put({
    type: 'workspaces/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(createWorkspace, {
    key,
    workspaceId,
    name,
    description,
    owner,
    members,
  })
  if (response && !_.isEmpty(response)) {
    const list = yield call(getListWorkspace, owner)
    let dataPayload = {
      list,
    }
    if (switchWorkspace) {
      yield store.set(`app.workspaces.current`, response.workspaceId)
      dataPayload = {
        key,
        workspaceId,
        name,
        description,
        owner,
        members,
        list,
      }
    }
    yield put({
      type: 'workspaces/SET_STATE',
      payload: dataPayload,
    })
  }
  yield put({
    type: 'workspaces/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_LIST_WORKSPACE({ payload: { user } }) {
  yield put({
    type: 'workspaces/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(getListWorkspace, user)
  if (response && !_.isEmpty(response)) {
    yield put({
      type: 'workspaces/SET_STATE',
      payload: {
        list: response,
      }, // custom or change keys if needed
    })
  }
  yield put({
    type: 'workspaces/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CHANGE_WORKSPACE({ payload: { workspaceId } }) {
  // workspaceId bat buoc phai la string hoac type of workspace.id
  yield put({
    type: 'workspaces/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(getWorkspace, workspaceId)
  if (response && !_.isEmpty(response)) {
    yield put({
      type: 'workspaces/SET_STATE',
      payload: {
        key: response.key,
        name: response.name,
        workspaceId: response.workspaceId,
        description: response.description,
        owner: response.owner,
        members: response.members,
      }, // custom or change keys if needed
    })
    yield store.set(`app.workspaces.current`, response.workspaceId)
  }
  yield put({
    type: 'workspaces/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_WORKSPACE() {
  yield put({
    type: 'workspaces/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const storedWorkspaceId = store.get(`app.workspaces.current`)
  if (storedWorkspaceId) {
    const info = yield call(getWorkspace, storedWorkspaceId)
    if (info && !_.isEmpty(info)) {
      yield put({
        type: 'workspaces/SET_STATE',
        payload: {
          key: info.key,
          name: info.name,
          workspaceId: info.workspaceId,
          description: info.description,
          owner: info.owner,
          members: info.members,
        },
      })
    }
  } else {
    const response = yield call(currentWorkspace)
    if (response && !_.isEmpty(response)) {
      yield put({
        type: 'workspaces/SET_STATE',
        payload: {
          key: response.key,
          name: response.name,
          workspaceId: response.workspaceId,
          description: response.description,
          owner: response.owner,
          members: response.members,
        },
      })
      yield store.set(`app.workspaces.current`, response.workspaceId)
    }
  }
  yield put({
    type: 'workspaces/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_WORKSPACE, CHANGE_WORKSPACE),
    takeEvery(actions.CREATE_WORKSPACE, CREATE_WORKSPACE),
    takeEvery(actions.LOAD_LIST_WORKSPACE, LOAD_LIST_WORKSPACE),
    takeEvery(actions.LOAD_CURRENT_WORKSPACE, LOAD_CURRENT_WORKSPACE),
    // LOAD_CURRENT_WORKSPACE(), // run once on app load to get current workspace
  ])
}
