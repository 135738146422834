import { postJson, getJson } from 'utils/utils'

const sortFunction = (a, b) => {
  if (a.allocationstate < b.allocationstate) return 1
  if (a.allocationstate > b.allocationstate) return -1
  if (a.name > b.name) return 1
  return -1
}

export default async function getListZones() {
  return new Promise(resolve => {
    getJson('/zones')
      .then(list => {
        const listZone = list.results.sort(sortFunction)
        resolve(listZone)
      })
      .catch(err => {
        resolve([])
      })
  })
}
