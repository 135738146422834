import constant from 'web-constant'

export const LOGIN_ERROR = 'LOGIN_ERROR'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export const LOGIN_LOADING = 'LOGIN_LOADING'

export const RESET_PASSWORD = 'RESET_PASSWORD'

export function loginWithSSO() {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING,
    })
    window.location = `${constant.AUTH_URL}/login/sso`
  }
}

export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD,
    })
  }
}
