import React from 'react'
import {connect} from 'react-redux'
import {Dropdown, Menu} from 'antd'
import {FormattedMessage} from 'react-intl'
import {withRouter} from 'react-router-dom'
import {PropTypes} from 'prop-types'
import constant from 'web-constant';
import {logoutUser} from '../../../../../redux/user/actions'
import styles from './style.module.scss'

const mapStateToProps = state => ({
  logoutUserRedux: PropTypes.func.isRequired,
  user: state.user,
})
const dispatchStateToProps = dispatch => ({
  logoutUserRedux: history => dispatch(logoutUser(history)),
})

@withRouter
@connect(mapStateToProps, dispatchStateToProps)
class ProfileMenu extends React.Component {
  logout = () => {
    const { logoutUserRedux, history } = this.props
    logoutUserRedux(history)
  }

  render() {
    const { user } = this.props
    const { userInfo } = user || ''
    const menu = (
      <Menu selectable={false}>
        <Menu.Item onClick={() =>{
          window.open(constant.ACCOUNT_CLIENT_URL, '_blank').focus();
        }}
        >
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.domain" />:{' '}
            </strong>
            {user.user ? user.user : ''}
            <br />
            <strong>
              <FormattedMessage id="topBar.profileMenu.email" />:{' '}
            </strong>
            {userInfo ? userInfo.mail : ''}
            <br />
            <strong>
              <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
            </strong>
            {userInfo ? userInfo.mobilePhone : ''}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="javascript: void(0);" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <span className="d-none d-xl-inline">
            <strong>{userInfo ? userInfo.displayName : user.user}</strong>
          </span>
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
