export async function getLeftMenuData() {
  return [
    {
      title: 'Dashboard',
      key: '/dashboard',
      url: '/dashboard',
      icon: 'icmn icmn-home',
    },
    {
      divider: true,
    },
    {
      title: 'Compute Engine',
      key: '/compute-engine',
      url: '/compute-engine/vm-instances',
      icon: 'fa fa-microchip',
    },
    {
      divider: true,
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
  ]
}
export async function getTopMenuData() {
  return [
    {
      title: 'Home',
      key: '/dashboard',
      url: '/dashboard',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Compute Engine',
      key: '/compute-engine',
      url: '/compute-engine/vm-instances',
      icon: 'fa fa-microchip',
    },
    // {
    //   title: 'Settings',
    //   key: 'settings',
    //   icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    // },
  ]
}
export async function getComputeEngineMenuData() {
  return [
    {
      title: 'Compute Engine',
      icon: 'fa fa-microchip',
      disabled: true,
    },
    {
      divider: true,
    },
    {
      title: 'VM instances',
      url: '/compute-engine/vm-instances',
      key: 'vm-instances',
      hideChildren: true,
      children: [
        {
          title: 'Create',
          url: '/compute-engine/vm-instances/create',
          key: 'vm-instances',
        },
      ],
    },
  ]
}

export async function getEc2MenuData() {
  return [
    {
      title: 'CE Dashboard',
      key: 'ce',
      url: '/ce',
    },
    {
      divider: true,
    },
    {
      title: 'Instances',
      key: 'instances',
      children: [
        {
          title: 'Instances',
          url: '/ce/instances',
          key: 'ins',
        },
        {
          title: 'Instance Types',
          url: '/ce/instances/types',
          key: 'instance-types',
        },
      ],
    },
    {
      title: 'Elastic Block Store',
      key: 'block-store',
      children: [
        {
          title: 'Volumes',
          url: '/ce/elastic-block-store/volumes',
          key: 'volumes',
        },
        {
          title: 'Snapshots',
          url: '/ce/elastic-block-store/snapshots',
          key: 'snapshots',
        },
        // {
        //   title: 'VM Snapshots',
        //   url: '/ce/elastic-block-store/vm-snapshots',
        //   key: 'vm-snapshots',
        // },
      ],
    },
    {
      title: 'Network & Security',
      key: 'network-security',
      hideChildren: true,
      children: [
        // {
        //   title: 'VPC',
        //   url: '/ce/network/vpc',
        //   key: 'vpc',
        // },
        {
          title: 'Security Groups',
          url: '/ce/network/security-groups',
          key: 'security-groups',
        },
        {
          title: 'Elastic IPs',
          url: '/ce/network/elastic-ips',
          key: 'elastic-ips',
        },
        // {
        //   title: 'ACL lists',
        //   url: '/ce/network/acl-lists',
        //   key: 'acl-lists',
        // },
        {
          title: 'Key Pairs',
          key: 'key-pairs',
          url: '/ce/keypair',
        },
      ],
    },
    {
      title: 'VPC',
      key: 'vpc',
      hideChildren: true,
      children: [
        {
          title: 'Your VPCs',
          key: 'your-vpcs',
          url: '/ce/vpcs',
        },
        {
          title: 'Subnets',
          key: 'subnet',
          url: '/ce/subnets',
        },
      ],
    },
  ]
}
