import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout, Button } from 'antd'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import styles from './style.module.scss'
import IconCustom from '../../../CleanUIComponents/IconCustom'

const { Sider } = Layout
const { SubMenu, Divider } = Menu

const mapStateToProps = ({ menu, settings }) => ({
  menuData: menu.menuEc2Data,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isMenuOpen: menu.isMenuOpen,
})

@withRouter
@connect(mapStateToProps)
class MenuEc2 extends React.Component {
  state = {
    selectedKeys: store.get('app.menu.selectedKeys'),
    openedKeys: store.get('app.menu.openedKeys') || [],
  }

  componentWillMount() {
    this.setSelectedKeys(this.props)
    this.setOpenedKeys(this.props)
  }


  componentWillReceiveProps(newProps) {
    if (newProps.isMenuCollapsed && !newProps.isMobileView) {
      this.setState({
        openedKeys: [],
      })
    }
    this.setSelectedKeys(newProps)
  }

  setSelectedKeys = props => {
    const { menuData } = this.props
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])

    // sort objMenu by url length descending
    const objMenu = flattenItems(menuData, 'children').sort((a, b) => {
      if (a.url && b.url) return (b.url.length - a.url.length)
      if (a.url) return -1
      return 1
    })
    // console.log(objMenu);
    // select the first (longest) url which match the current url
    const selectedItem = _.find(objMenu, (item) => {
      const currentUrl = props.location.pathname
      const { url } = item
      return (currentUrl && url && url === currentUrl.substring(0, url.length))
    })

    this.setState({
      selectedKeys: selectedItem ? [selectedItem.key] : store.get('app.menu.selectedKeys'),
    })
  }

  setOpenedKeys = props => {
    const { menuData } = this.props
    const flattenItems = Object.values(menuData).reduce((total = [], item) => {
      total.push(item.key)
      return total
    }, [])

    this.setState({
      openedKeys: flattenItems,
    })
  }

  onCollapse = (value, type) => {
    const { dispatch, isMenuCollapsed } = this.props
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })

    this.setOpenedKeys(this.props)
  }

  onOpenChange = openedKeys => {
    store.set('app.menu.openedKeys', openedKeys)
    this.setState({
      openedKeys,
    })
  }

  handleClick = e => {
    const { dispatch, isSettingsOpen, isMobileView, isMobileMenuOpen } = this.props
    store.set('app.menu.selectedKeys', [e.key])
    // close menu if mobile menu opened
    if (isMobileView) {
      setTimeout(() => {
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'isMobileMenuOpen',
            value: !isMobileMenuOpen,
          },
        })
      }, 500)
    }
    this.setState({
      selectedKeys: [e.key],
      // openKeys: e.keyPath,
    })
  }

  generateMenuItems = () => {
    const { menuData = [] } = this.props
    const generateItem = item => {
      const { key, title, url, icon, disabled } = item
      if (item.divider) {
        return <Divider key={Math.random()} />
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${styles.icon}`} />}
                <span className={styles.title}>{title}</span>
              </a>
            ) : (
              <Link to={url}>
                {icon && <span className={`${icon} ${styles.icon}`} />}
                <span className={styles.title}>{title}</span>
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          <span className={styles.title}>{title}</span>
          {icon && <span className={`${icon} ${styles.icon}`} />}
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map(menuItem => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={styles.title}>{menuItem.title}</span>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
          </span>
        )
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  render() {
    const { selectedKeys, openedKeys } = this.state
    const { isMobileView, isMenuCollapsed, isLightTheme, isMenuOpen, location: { pathname } } = this.props
    const menuSettings = isMobileView
      ? {
          width: 240,
          collapsible: false,
          collapsed: false,
          onCollapse: this.onCollapse,
        }
      : {
          width: 240,
          collapsible: true, // turn it true if you want to colapse menu
          collapsed: isMenuCollapsed,
          onCollapse: this.onCollapse,
          breakpoint: 'md',
        }

    const menu = this.generateMenuItems()
    const menuHeaderText = process.env.REACT_APP_MENU_TITLE
    return (
      <div>
        <Button
          style={{
            height: '100vh',
            position: 'fixed',
            zIndex: 1,
            display: 'inline-flex',
            paddingTop: '80px',
          }}
          onClick={this.onCollapse}
        >
          <IconCustom iconName="MenuOutlined" />
        </Button>
        {!menuSettings.collapsed && (
          <Sider
            style={{ overflow: 'auto', height: '100vh', position: 'sticky', top: 0, left: 0 }}
            {...menuSettings}
            trigger={null}
            className={isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
          >
            <div className={styles.logo}>
              <div className={styles.logoContainer}>
                <img src="/resources/images/epc-logo.png" alt="EPC-logo" />
              </div>
            </div>
            <Scrollbars
              className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
              autoHide
            >
              <Menu
                theme={isLightTheme ? 'light' : 'dark'}
                onClick={this.handleClick}
                selectedKeys={selectedKeys}
                openKeys={openedKeys}
                onOpenChange={this.onOpenChange}
                mode="inline"
                className={styles.navigation}
              >
                <Menu.Item onClick={this.onCollapse} style={{display: 'flex', justifyContent: 'space-between'}}>
                  <strong style={{fontSize: '15px'}}>{menuHeaderText}</strong>
                  <span style={{ float: 'right' }}>X</span>
                </Menu.Item>
                <Divider key={Math.random()} />
                {menu}
              </Menu>
            </Scrollbars>
          </Sider>
        )}
      </div>
    )
  }
}

export default MenuEc2
