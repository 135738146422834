import { all } from 'redux-saga/effects'
import menu from './menu/sagas'
import settings from './settings/sagas'
import workspaces from './workspaces/sagas'
import zones from './zones/sagas'
import providers from './providers/sagas'
import serviceOfferings from './serviceOfferings/sagas'
import instances from './instances/sagas'
import volumes from './volumes/sagas'
import snapshots from './snapshots/sagas'
import securityGroups from './securityGroups/sagas'
import elasticIps from './elasticIps/sagas'
import keyPairs from './keyPairs/sagas'
import subnets from './subnets/sagas'
import vpcs from './vpcs/sagas'

export default function* rootSaga() {
  yield all([
    // user(),
    menu(),
    settings(),
    workspaces(),
    zones(),
    providers(),
    serviceOfferings(),
    instances(),
    volumes(),
    snapshots(),
    securityGroups(),
    elasticIps(),
    keyPairs(),
    vpcs(),
    subnets(),
  ])
}
