import { all, call, put, takeEvery } from 'redux-saga/effects'
import getElasticIps from 'services/elasticIps'
import { fetchElasticIpsFailure, fetchElasticIpsRequested, fetchElasticIpsSuccess } from './actions'

export function* fetchElasticIps(action) {
  yield put(fetchElasticIpsRequested)
  const response = yield call(getElasticIps, action.payload)
  if (response.error) {
    yield put(fetchElasticIpsFailure(response.error))
  } else {
    yield put(fetchElasticIpsSuccess(response.data))
  }
}

export function* watchFetchElasticIps() {
  yield takeEvery('FETCH_ELASTIC_IPS', fetchElasticIps)
}

export default function* rootSaga() {
  yield all([watchFetchElasticIps()])
}
