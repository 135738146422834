import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Dashboards
  {
    path: '/dashboard',
    exact: true,
    component: loadable(() => import('views/dashboard')),
  },
  // EC2 Dashboard
  {
    path: '/ce',
    exact: true,
    component: loadable(() => import('views/dashboard')),
  },
  {
    path: '/ce/instances',
    exact: true,
    component: loadable(() => import('views/instances')),
  },
  {
    path: '/ce/launch-instance-wizard',
    exact: true,
    component: loadable(() => import('views/launch-instance-wizard')),
  },
  {
    path: '/ce/instances/launch-templates',
    exact: true,
    component: loadable(() => import('views/ec2-launch-templates')),
  },
  {
    path: '/ce/instances/create-template',
    exact: true,
    component: loadable(() => import('views/create-template')),
  },
  {
    path: '/ce/instances/types',
    exact: true,
    component: loadable(() => import('views/instances/instance-type')),
  },
  {
    path: '/ce/elastic-block-store/volumes',
    exact: true,
    component: loadable(() => import('views/elastic-block-store/volumes')),
  },
  {
    path: '/ce/elastic-block-store/volumes/create-volume',
    exact: true,
    component: loadable(() => import('views/create-volume')),
  },
  {
    path: '/ce/elastic-block-store/snapshots',
    exact: true,
    component: loadable(() => import('views/elastic-block-store/snapshots')),
  },
  {
    path: '/ce/elastic-block-store/snapshots/create-snapshot',
    exact: true,
    component: loadable(() => import('views/create-snapshot')),
  },
  {
    path: '/ce/network/security-groups',
    exact: true,
    component: loadable(() => import('views/network/security-groups')),
  },
  {
    path: '/ce/network/elastic-ips',
    exact: true,
    component: loadable(() => import('views/network/elastic-ips')),
  },
  {
    path: '/ce/network/security-groups/:id',
    exact: true,
    component: loadable(() => import('views/network/security-groups/detail')),
  },
  {
    path: '/ce/keypair',
    exact: true,
    component: loadable(() => import('views/keypairs')),
  },
  {
    path: '/ce/vpcs',
    exact: true,
    component: loadable(() => import('views/vpc')),
  },
  {
    path: '/ce/vpcs/:id',
    exact: true,
    component: loadable(() => import('views/vpc/edit-cidr')),
  },
  {
    path: '/ce/subnets',
    exact: true,
    component: loadable(() => import('views/subnet')),
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/ce" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
