import { all, call, put, takeEvery } from 'redux-saga/effects'
import getVolumes from 'services/volumes'
import { fetchVolumesFailure, fetchVolumesRequested, fetchVolumesSuccess } from './actions'

export function* fetchVolumes() {
  yield put(fetchVolumesRequested())
  const response = yield call(getVolumes)
  if (response.error) {
    yield put(fetchVolumesFailure(response.error))
  } else {
    yield put(fetchVolumesSuccess(response.data))
  }
}

export function* watchFetchVolumes() {
  yield takeEvery('FETCH_VOLUMES', fetchVolumes)
}

export default function* rootSaga() {
  yield all([watchFetchVolumes()])
}
