import { all, takeEvery, put, call } from 'redux-saga/effects'
import _ from 'lodash'
import getListZones from 'services/zones'
import store from 'store'
import actions from './actions'

export function* LOAD_LIST_ZONE() {
  yield put({
    type: 'zones/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(getListZones)
  if (response && !_.isEmpty(response)) {
    yield put({
      type: 'zones/SET_STATE',
      payload: {
        listZones: response,
      },
    })
    yield store.set(`app.zones.listZones`, response)
    // init current zone
    const storedZone = store.get(`app.zones.current`)
    if (!storedZone || !storedZone.id || !response.find(zn => zn.id === storedZone.id)) {
      yield put({
        type: 'zones/SET_STATE',
        payload: {
          currentZone: response[0],
        },
      })
      yield store.set(`app.zones.current`, response[0])
    }
  }
  yield put({
    type: 'zones/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CHANGE_ZONE({ payload: { zoneId } }) {
  yield put({
    type: 'zones/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const listZones = yield store.get(`app.zones.listZones`)
  if (Array.isArray(listZones) && listZones.length > 0) {
    const newZone = listZones.find(zone => zone.id === zoneId)
    const currentZone = yield store.get(`app.zones.current`)
    if (!currentZone || currentZone.id !== zoneId) {
      yield put({
        type: 'zones/SET_STATE',
        payload: {
          currentZone: newZone,
        },
      })
      yield store.set(`app.zones.current`, newZone)
    }
  }
  yield put({
    type: 'zones/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_ZONE() {
  yield put({
    type: 'zones/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const listZones = store.get(`app.zones.listZones`)
  if (Array.isArray(listZones) && listZones.length > 0) {
    const storedZone = store.get(`app.zones.current`)
    const storedZoneId = storedZone ? storedZone.id : ''
    const refreshCurrentZone = listZones.find(zone => zone.id === storedZoneId) || listZones[0]
    yield put({
      type: 'zones/SET_STATE',
      payload: {
        currentZone: refreshCurrentZone,
      },
    })
    yield store.set(`app.zones.current`, refreshCurrentZone)
  }
  yield put({
    type: 'zones/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_ZONE, CHANGE_ZONE),
    takeEvery(actions.LOAD_LIST_ZONE, LOAD_LIST_ZONE),
    takeEvery(actions.LOAD_CURRENT_ZONE, LOAD_CURRENT_ZONE),
    LOAD_LIST_ZONE(),
    LOAD_CURRENT_ZONE(), // run once on app load to get zones
  ])
}
