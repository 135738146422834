import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Icon from '@ant-design/icons'

class IconCustom extends Component {
  render() {
    const { className, iconName, rotate, spin, style, twoToneColor } = this.props
    const Custom = Icon[iconName]
    return (
      <Custom
        className={className}
        rotate={rotate}
        spin={spin}
        style={style}
        twoToneColor={twoToneColor}
      />
    )
  }
}

IconCustom.propsTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  rotate: PropTypes.number,
  spin: PropTypes.bool,
  style: PropTypes.object,
  twoToneColor: PropTypes.string,
}

IconCustom.defaultProps = {
  className: '',
  rotate: 0,
  spin: false,
  style: {},
  twoToneColor: '',
}

export default IconCustom
