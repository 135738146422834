import { actions } from './actions'

const initState = {
  loading: true,
  error: false,
  data: [],
}

const elasticIpsReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.FETCH_ELASTIC_IPS_REQUESTED: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.FETCH_ELASTIC_IPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      }
    }
    case actions.FETCH_ELASTIC_IPS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    }
    default:
      return state
  }
}

export default elasticIpsReducer
