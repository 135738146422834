export const actions = {
  FETCH_INSTANCES_REQUESTED: 'FETCH_INSTANCES_REQUESTED',
  FETCH_INSTANCES_SUCCESS: 'FETCH_INSTANCES_SUCCESS',
  FETCH_INSTANCES_FAILURE: 'FETCH_INSTANCES_FAILURE',
}

export const fetchInstancesRequested = () => ({
  type: actions.FETCH_INSTANCES_REQUESTED,
})

export const fetchInstancesSuccess = payload => ({
  type: actions.FETCH_INSTANCES_SUCCESS,
  payload,
})

export const fetchInstancesFailure = error => ({
  type: actions.FETCH_INSTANCES_FAILURE,
  error,
})
