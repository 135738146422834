import { all, put, call } from 'redux-saga/effects'
import {
  getLeftMenuData,
  getTopMenuData,
  getComputeEngineMenuData,
  getEc2MenuData,
} from 'services/menu'

export function* GET_DATA() {
  const menuLeftData = yield call(getEc2MenuData)
  const menuTopData = yield call(getTopMenuData)
  const menuComputeEngineData = yield call(getComputeEngineMenuData)
  const menuEc2Data = yield call(getEc2MenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData,
      menuTopData,
      menuComputeEngineData,
      menuEc2Data,
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
