export const actions = {
  FETCH_SECURITY_GROUPS_REQUESTED: 'FETCH_SECURITY_GROUPS_REQUESTED',
  FETCH_SECURITY_GROUPS_SUCCESS: 'FETCH_SECURITY_GROUPS_SUCCESS',
  FETCH_SECURITY_GROUPS_FAILURE: 'FETCH_SECURITY_GROUPS_FAILURE',
}

export const fetchSecurityGroupsRequested = () => ({
  type: actions.FETCH_SECURITY_GROUPS_REQUESTED,
})

export const fetchSecurityGroupsSuccess = payload => ({
  type: actions.FETCH_SECURITY_GROUPS_SUCCESS,
  payload,
})

export const fetchSecurityGroupsFailure = error => ({
  type: actions.FETCH_SECURITY_GROUPS_FAILURE,
  error,
})
