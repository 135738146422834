import { all, call, put, takeEvery } from 'redux-saga/effects'
import getSubnets from 'services/subnets'
import { fetchSubnetsFailure, fetchSubnetsRequested, fetchSubnetsSuccess } from './actions'

export function* fetchSubnets() {
  yield put(fetchSubnetsRequested)
  const response = yield call(getSubnets)
  if (response.error) {
    yield put(fetchSubnetsFailure(response.error))
  } else {
    yield put(fetchSubnetsSuccess(response.data))
  }
}

export function* watchFetchSubnets() {
  yield takeEvery('FETCH_SUBNETS', fetchSubnets)
}

export default function* rootSaga() {
  yield all([watchFetchSubnets()])
}
